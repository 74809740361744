// BKKC Variables
$color-bkkc: rgb(255, 198, 11);
$color-discord: rgb(114, 152, 218);
$color-twitch: rgb(145, 70, 255);
$transition: 0.3s ease-out all;

// Bulma overrides
$primary: $color-bkkc;
$black-bis: hsl(0, 0%, 7%);
$black-ter: hsl(0, 0%, 14%);
$grey-darker: hsl(0, 0%, 21%);
$grey-dark: hsl(0, 0%, 29%);
$grey-light: hsl(0, 0%, 71%);
$grey-lighter: hsl(0, 0%, 86%);
$white-ter: hsl(0, 0%, 96%);
$white-bis: hsl(0, 0%, 98%);

$input-background-color: $black-bis;
$input-border-color: $black-ter;

$table-background-color: $black-bis;
