@import '../../variables';

.scoreboard {
  .scoreboardHashtag {

  }

  .scoreboardHeader {
    background: white;
    margin-bottom: 2rem;
    padding: 0.5em 0.75em;

    .scoreboardLogo {

    }

    .scoreboardTitle {
      color: black;
      font-size: 2.5rem;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .scoreboardTable {
    background: transparent;

    thead tr, tbody {
      background-color: rgba(0,0,0,0.5);
    }

    thead {
      th {
        text-align: center;

        &:first-of-type {
          text-align: left;
          width: 60%;
        }
      }

      &::after {
        content: '-';
        display: block;
        line-height: 0.5rem;
        visibility: hidden;
      }
    }

    th {
      border-width: 0;
      color: $grey-light;
      font-size: 1.15rem;
      font-weight: 600;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    tbody {
      font-size: 1.5rem;
      font-weight: 700;
      text-transform: uppercase;

      tr:nth-of-type(even) {
        background-color: rgba(0,0,0,0.2);
      }

      td {
        border-width: 0;

        &:nth-child(n + 2) {
          text-align: center;
        }
      }

      tr:hover {
        background-color: rgba(255,255,255,0.05);
      }
    }

    .place {
      color: $grey-light;
      display: inline-block;
      width: 3.5rem;
    }

    .total {
      background-color: rgba(255, 255, 255, 0.1);
      font-size: 1.5rem;
      text-shadow: 0px 1px 1px black;
    }
  }
}