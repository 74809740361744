@import "../../_variables.scss";

.buttons {
  margin-top: 2rem;
}

button.custom {
  box-sizing: border-box;
  font-size: 2rem;
  padding: 3rem;
  transition: $transition;
  width: calc(50% - 0.25rem);

  &:last-of-type {
    margin-right: 0 !important;
  }

  &.register {
    background: rgba(255, 198, 11, 0.2);
    border: 2px solid $primary;
    color: white;

    &:hover,
    &:focus {
      background: rgba(255, 198, 11,0.5);
      border: 2px solid $primary;
      color: white;
    }

    &:active {
      background: $primary;
      color: white;
    }
  }

  &.discord {
    background: rgba(114, 152, 218, 0.2);
    border: 2px solid $color-discord;
    color: white;

    &:hover,
    &:focus {
      background: rgba(114, 152, 218, 0.5);
      border: 2px solid $color-discord;
      color: white;
    }

    &:active {
      background: $color-discord;
      color: white;
    }
  }
}

@media screen and (max-width: 768px) {
  button.custom {
    margin-right: 0 !important;
    margin-left: 0 !important;
    margin-bottom: 2rem;
    width: 100% !important;
  }
}